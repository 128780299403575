<template>
    <v-card flat :disabled="isSpaceResting">
        <v-card-title>
            <div class="mb-3 d-flex flex-column">
                <div class="d-flex align-center">
                    <span class="secondary--text font-weight-bold">Snapshots</span>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                icon
                                :loading="fetchingSnapshots"
                                v-on="on"
                                @click="$store.dispatch('instanceStore/fetchInstanceSnapshots', $route.params.iid)">
                                <v-icon>refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh snapshots</span>
                    </v-tooltip>
                </div>
            </div>
        </v-card-title>
        <v-card-text>
            <div v-if="nonDevelopmentSnapshots.length && !fetchingSnapshots">
                <v-row>
                    <v-col class="d-flex justify-start flex-wrap">
                        <div v-for="(item, index) in Object.values(snapshotCheckboxBinders)" :key="index" class="mr-5">
                            <v-checkbox class="ma-0 pa-0" v-model="item.status">
                                <template v-slot:label>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <div class="d-flex align-center" v-on="on">
                                                <v-icon :color="item.color">fiber_manual_record</v-icon>
                                                <span class="secondary--text caption text-uppercase">{{ item.text }}</span>
                                            </div>
                                        </template>
                                        <span>{{ item.description }}</span>
                                    </v-tooltip>
                                </template>
                            </v-checkbox>
                        </div>
                    </v-col>
                </v-row>
                <v-divider class="mb-5"></v-divider>
                <v-timeline v-if="nonDevelopmentSnapshots.length" dense class="shepherd-snapshot-restore-1">
                    <v-timeline-item fill-dot right small :color="dotColor(snapshot)" v-for="snapshot in filteredSnapshots" :key="snapshot.snid" class="pb-0">
                        <v-row class="align-center my-1">
                            <v-col cols="auto">
                                <div class="mr-4">
                                    {{ snapshot.snapshot_timestamp | dateTimeToHuman }}
                                </div>
                            </v-col>
                            <v-col>
                                <v-card elevation="3" class="px-5">
                                    <v-row align="center">
                                        <v-col cols="6">
                                            <router-link
                                                :to="{
                                                    name: 'snapshot-overview',
                                                    params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: snapshot.snid }
                                                }"
                                                class="text-decoration-none secondary--text subtitle-1">
                                                <b>{{ snapshot.long_id }}</b>
                                            </router-link>
                                            <div v-if="snapshot.description">
                                                {{ snapshot.description }}
                                            </div>
                                        </v-col>
                                        <v-col cols="1">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" icon>
                                                        <v-icon :color="snapshot.filesystem_prefix ? 'success' : 'info'">
                                                            {{ getSnapshotStatusIcon(snapshot) }}
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <div>
                                                    {{ getSnapshotStatusDescription(snapshot) }}
                                                </div>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-tooltip top v-if="snapshot.fhl_total_bytes">
                                                <template v-slot:activator="{ on }">
                                                    <v-chip v-on="on">{{ humanFileSize(snapshot.fhl_total_bytes) }}</v-chip>
                                                </template>
                                                <div>Backup size</div>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="3">
                                            <div v-if="isInstanceEditor" class="d-flex align-center justify-end">
                                                <div
                                                    v-if="!isDistributedInstance && !isSpaceInAnyRestStateExceptPreResting"
                                                    class="shepherd-snapshot-restore-2">
                                                    <snapshot-restore-dialog :snapshotData="snapshot" :isRestoreToCurrentInstance="true" />
                                                </div>
                                                <div v-if="!isDistributedInstance" class="px-2">
                                                    <snapshot-restore-dialog :snapshotData="snapshot" :isRestoreToCurrentInstance="false" />
                                                </div>
                                                <div class="shepherd-snapshot-restore-3">
                                                    <snapshot-delete-dialog :snapshotData="snapshot" :disabled="isInstanceArchived && !isSpaceAdmin" />
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-timeline-item>
                </v-timeline>
            </div>
            <div v-else-if="fetchingSnapshots">
                <div
                    v-for="item in [
                        { id: 'c1', opacity: 1 },
                        { id: 'c2', opacity: 0.75 },
                        { id: 'c3', opacity: 0.5 }
                    ]"
                    :key="item.id">
                    <div :style="{ opacity: item.opacity }">
                        <v-skeleton-loader type="list-item-three-line" class="mx-auto" />
                    </div>
                </div>
            </div>

            <v-banner v-else-if="!fetchingSnapshots && !nonDevelopmentSnapshots.length" two-line>
                <v-avatar slot="icon" color="white" size="60">
                    <v-icon x-large icon="info" color="info">info</v-icon>
                </v-avatar>
                <div style="max-width: 900px" class="d-flex flex-column">
                    <span class="font-weight-bold">No snapshots found</span>
                    <span class="mt-1">
                        Snapshots are immutables states of previous work that allow you to reproduce results and restore files, applications, and data. Check
                        the
                        <a href="https://docs.nuvolos.cloud/features/snapshots/create-a-snapshot" target="_blank">documentation here</a>
                        for information on how to create a snapshot.
                    </span>
                </div>
            </v-banner>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { sortArray, truncateText, humanFileSize } from '@/utils'
import { enumsData } from '@/mixins/enums'
import { snapshotEnums } from '@/mixins/snapshot'
import Shepherd from 'shepherd.js'
import tour from '@/mixins/tour'

const SnapshotDeleteDialog = () => import('@/modules/snapshot/components/TheSnapshotDeleteDialog.vue')
const SnapshotRestoreDialog = () => import('@/modules/snapshot/components/TheSnapshotRestore.vue')

export default {
    mixins: [enumsData, snapshotEnums, tour],
    components: { SnapshotDeleteDialog, SnapshotRestoreDialog },
    data() {
        return {
            snapshotCheckboxBinders: {
                quickSnapshots: {
                    status: true,
                    color: 'blue',
                    text: 'Quick Snapshots',
                    description: 'Snapshots saved by the user via the Quick Snapshot action'
                },
                autoSnapshots: {
                    status: true,
                    color: 'yellow darken-1',
                    text: 'Auto Snapshots',
                    description: 'Snapshots saved by Nuvolos when operations like object distribution or snapshot restoration are performed'
                },
                detailedSnapshots: {
                    status: true,
                    color: 'success',
                    text: 'Detailed Snapshots',
                    description: 'Snapshots created with a description by the user'
                },
                archivedSnapshots: {
                    status: true,
                    color: 'warning',
                    text: 'Archived Snapshots',
                    description: 'Snapshots created during space archival'
                },
                restedSnapshots: {
                    status: true,
                    color: 'purple',
                    text: 'Rested Snapshots',
                    description: 'Rested snapshots'
                }
            },
            tourId: 'show_snapshot_restore_guide',
            tourHidden: false
        }
    },
    methods: {
        snapshotOperation: function (sourceSnapshotId, operation) {
            const pars = {
                oid: this.$route.params.oid,
                sid: this.$route.params.sid,
                iid: this.$route.params.iid,
                snid: sourceSnapshotId
            }
            if (operation === 'restoreToCurrentInstance') {
                this.$router.push({
                    name: 'snapshot-restore',
                    params: pars
                })
            } else if (operation === 'restoreToAnotherInstance') {
                this.$router.push({
                    name: 'instance-upload-to-development',
                    params: pars
                })
            } else if (operation === 'deleteSnapshot') {
                this.$router.push({
                    name: 'snapshot-delete',
                    params: pars
                })
            }
        },
        truncateText: function (text, maxLength) {
            return truncateText(text, maxLength)
        },
        dotColor: function (snapshot) {
            const { quickSnapshots, autoSnapshots, detailedSnapshots, archivedSnapshots, restedSnapshots } = this.snapshotCheckboxBinders

            switch (snapshot.snapshot_type) {
                case this.snapshotTypes.AUTO_SNAPSHOT:
                    return autoSnapshots.color
                case this.snapshotTypes.QUICK_SNAPSHOT:
                    return quickSnapshots.color
                case this.snapshotTypes.DETAILED_SNAPSHOT:
                    return detailedSnapshots.color
                case this.snapshotTypes.ARCHIVED_SNAPSHOT:
                    return archivedSnapshots.color
                case this.snapshotTypes.RESTED_SNAPSHOT:
                    return restedSnapshots.color
                default:
                    return 'secondary'
            }
        },
        humanFileSize(bytes, si) {
            return humanFileSize(bytes, si)
        }
    },
    computed: {
        ...mapState('instanceStore', ['fetchingSnapshots']),
        ...mapState(['userMetadata', 'fetchingUserInfo']),
        ...mapGetters('instanceStore', ['isInstanceEditor', 'isDistributedInstance', 'isInstanceArchived']),
        ...mapGetters('snapshotStore', ['nonDevelopmentSnapshots']),
        ...mapGetters('spaceStore', ['isSpaceAdmin', 'isSpaceInAnyRestStateExceptPreResting', 'isSpaceResting']),

        filteredSnapshots() {
            const snapshots = this.nonDevelopmentSnapshots.filter(
                snapshot =>
                    (snapshot.snapshot_type === this.snapshotTypes.QUICK_SNAPSHOT && this.snapshotCheckboxBinders.quickSnapshots.status) ||
                    (snapshot.snapshot_type === this.snapshotTypes.AUTO_SNAPSHOT && this.snapshotCheckboxBinders.autoSnapshots.status) ||
                    (snapshot.snapshot_type === this.snapshotTypes.DETAILED_SNAPSHOT && this.snapshotCheckboxBinders.detailedSnapshots.status) ||
                    (snapshot.snapshot_type === this.snapshotTypes.ARCHIVED_SNAPSHOT && this.snapshotCheckboxBinders.archivedSnapshots.status) ||
                    (snapshot.snapshot_type === this.snapshotTypes.RESTED_SNAPSHOT && this.snapshotCheckboxBinders.restedSnapshots.status)
            )
            return sortArray(snapshots, 'snid', 'descending', false)
        },
        showSnapshotRestoreGuide() {
            return !this.fetchingSnapshots && !this.fetchingUserInfo && this.userMetadata.show_snapshot_restore_guide
        }
    },
    watch: {
        showSnapshotRestoreGuide: {
            handler: function (show) {
                if (show) {
                    this.$nextTick(() => {
                        this.tour = new Shepherd.Tour({
                            useModalOverlay: true,
                            defaultStepOptions: {
                                cancelIcon: {
                                    enabled: true
                                },
                                scrollTo: { behavior: 'smooth', block: 'center' }
                            }
                        })
                        this.tour.addSteps([
                            {
                                title: 'Snapshot timeline',
                                text: `This is your snapshot timeline, where you can delete a snapshot or restore a previous saved snapshot to work on it more.`,
                                attachTo: {
                                    element: '.shepherd-snapshot-restore-1',
                                    on: 'top'
                                },
                                buttons: [
                                    {
                                        action() {
                                            return this.next()
                                        },
                                        text: 'Next'
                                    }
                                ],
                                id: 'step-1'
                            },
                            {
                                title: 'Restore snapshot',
                                text: `You can restore a previously saved state of an instance (snapshot) by clicking this button.`,
                                attachTo: {
                                    element: '.shepherd-snapshot-restore-2',
                                    on: 'top'
                                },
                                buttons: [
                                    {
                                        action() {
                                            return this.back()
                                        },
                                        classes: 'shepherd-button-primary',
                                        text: 'Back'
                                    },
                                    {
                                        action() {
                                            return this.next()
                                        },
                                        text: 'Next'
                                    }
                                ],
                                id: 'step-2'
                            },
                            {
                                title: 'Delete snapshot',
                                text: `If you need to delete a snapshot, you can do it here.`,
                                attachTo: {
                                    element: '.shepherd-snapshot-restore-3',
                                    on: 'top'
                                },
                                buttons: [
                                    {
                                        action() {
                                            return this.back()
                                        },
                                        classes: 'shepherd-button-primary',
                                        text: 'Back'
                                    },
                                    {
                                        action() {
                                            return this.complete()
                                        },
                                        text: 'Got it!'
                                    }
                                ],
                                id: 'step-3'
                            }
                        ])

                        this.tour.start()
                    })
                }
            },
            immediate: true
        }
    }
}
</script>
